module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.codelinaro.org"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://df67765ff240452ca30e03480431d0f0@o448810.ingest.sentry.io/5339411","environment":"staging","release":"ec6aa50551b2fd4272c0211dda4e9035c8245e95"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
